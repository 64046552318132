@tailwind base;
@tailwind components;
@tailwind utilities;

/* In your CSS file */
.hide-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}

* {
  --sb-track-color: #191313;
  --sb-thumb-color: #f4511e;
  --sb-size: 8px;

  scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
}

*::-webkit-scrollbar {
  width: var(--sb-size);
}

*::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 5px;
}

@keyframes animateImg {
  0% {
    object-position: left;
  }

  100% {
    object-position: right;
  }
}

.img-d {
  animation-duration: 10s;
  animation-name: animateImg;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.exposure-video {
  filter: brightness(70%); /* Adjust brightness */

  /* Add more custom styles as needed */
}

.hidden-scrollbar {
  /* For Internet Explorer, Edge */
  -ms-overflow-style: none;

  /* For Firefox */
  scrollbar-width: none;

  /* For Chrome, Safari, Opera */
}

.hidden-scrollbar::-webkit-scrollbar {
  display: none;
}


/* App.css */

@keyframes roulette-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes roulette-right {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.animate-roulette-left {
  animation: roulette-left 0.5s ease-in-out forwards;
}

.animate-roulette-right {
  animation: roulette-right 0.5s ease-in-out forwards;
}
/* Assurez-vous d'inclure ce style dans votre fichier CSS */
button.remove-image {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

